const router_modular = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home/index"),
  },
  {
    path: "/collectionsInfo",
    name: "collectionsInfo",
    component: () => import("@/components/collectionsInfo/index"),
  },{
    path: "/bannerInfo",
    name: "bannerInfo",
    component: () => import("@/components/bannerInfo/index"),
  },
];
export default router_modular;
